import React, {Component} from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"
import "./GameNav.sass"

class GameNav extends Component {
  render() {
    const games = this.props.games;
    return (
        <section className="section game-nav">
            <div className="container">
                {/* <div className="columns is-1 is-mobile is-multiline"> */}
                <div className="level is-mobile">
                    {
                    games.map((item, index) => (
                        <div key={index} className="level-item">
                        <Link to={"/games/" + item.node.uid}>
                            <div className="box site-box game-icon">
                                <Img
                                  className="game-icon-image"
                                  fluid={ item.node.data.logo.localFile.childImageSharp.fluid }
                                  alt={ item.node.uid }
                                  style={{position: 'absolute', left: '0', top: '0', width: '100%', height: '100%'}}
                                />
                            </div>
                        </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
  }
}

export default GameNav;
