import React, {Component} from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"
import "./InfoBlocks.sass"

class InfoBlocks extends Component {
  render() {
    const infoItems = this.props.infoItems;

    return (
    <div>
        {
        infoItems.map((item, index) => {
            return (
                    <div key={index} className="info-block">
                        <div className={"columns " + (index % 2 == 0 ? 'column-reverse' : '')}>
                            <div className="column">
                                <div className="info-block-title subtitle">
                                    <h4>{item.info_block_title.text}</h4>
                                </div>
                                <div className="info-block-body" dangerouslySetInnerHTML={{ __html: item.info_block_content.html}}>
                                </div>
                            </div>
                            <div className="column is-one-third">
                                <figure className="image info-block-image">
                                    <Img fluid={ item.info_block_image.localFile.childImageSharp.fluid }></Img>
                                    <div className="bg-tint-2"></div>
                                </figure>

                            </div>
                        </div>
                    </div>
            )
        })}
    </div>
    );
  }
}

export default InfoBlocks;
