import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../../layout";
import config from "../../../data/SiteConfig";
import Img from "gatsby-image"
import "./betSite.sass"
import BetSiteListing from "../../components/BetSiteListing/BetSiteListing"
import BetCardRating from "../../components/Elements/BetCardRating/BetCardRating"
import Faq from "../../components/Faq/Faq"
import InfoBlocks from "../../components/InfoBlocks/InfoBlocks"
import GameNav from '../../components/GameNav/GameNav'
import PageShare from "../../components/PageShare/PageShare"
import Tabs from '../../elements/Tabs/Tabs';

export default class BetSite extends React.Component {

  render() {
    const betSite = this.props.data.prismicBetsite.data
    const games = this.props.data.prismicBetsite.data.games
    const paymentOptions = this.props.data.prismicBetsite.data.payment_options
    const uid = this.props.data.prismicBetsite.uid
    const sites = this.props.data.allPrismicBetsite.edges
    const buttonStyle = {
      backgroundColor: betSite.brand_color,
      minWidth: "50%",
      textTransform: "uppercase",
    }

    const url = "https://esportstips.net/betting-sites/" + uid
    return (
      <Layout>
        <div>
            <Helmet>
                <title>{`${betSite.metatitle.text}`}</title>
                <meta name="description" content={betSite.description.text} />
                <meta name="image" content={betSite.image.url} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <script type="application/ld+json">
                  {JSON.stringify(
                    {
                      "@context": "http://schema.org/",
                      "@type": "Review",
                      "itemReviewed": {
                        "@type": "Thing",
                        "image": betSite.image.url,
                        "name": betSite.name.text
                      },
                      "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": Math.floor((betSite.rating / 10) / 2)
                      },
                      "name": betSite.name.text + " review",
                      "author": {
                        "@type": "Person",
                        "name": "Esportstips team"
                      },
                      "reviewBody": betSite.description.text,
                      "publisher": {
                        "@type": "Organization",
                        "name": "Esportstips"
                      }
                    }
                  )}
                </script>
                <meta property="og:url" content={this.props.location.href} />
                <meta property="og:title" content={betSite.metatitle.text} />
                <meta property="og:description" content={betSite.description.text} />
                <meta property="og:image" content={betSite.image.url} />
                {/* <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ""}/> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ""}/>
                <meta name="twitter:title" content={betSite.metatitle.text} />
                <meta name="twitter:description" content={betSite.description.text} />
                <meta name="twitter:image" content={betSite.image.url} />
            </Helmet>
            {/* <section className="section">
            <div className="container">
            <div className="betsite-game-listing">
                  {
                    games.map((item, index) => {
                      return (
                        <div key={index} className="level-left">
												  <div className="level-item">
													  <Link to={"/games/" + item.game.document[0].uid}>
														  <div className="box betsite-game-image">
															  <Img fluid={ item.game.document[0].data.logo.localFile.childImageSharp.fluid } alt={ item.game.document[0].uid } />
														  </div>
													  </Link>
												  </div>
												</div>
                      )
                    })}
                  </div>
                </div>
            </section> */}

 
          <section className="section">
            <div className="betsite-section-bg">
              <Img fluid={ betSite.image.localFile.childImageSharp.fluid }
              style={{position: 'absolute', left: '0', top: '0', width: '100%', height: '100%'}}/>
              <div className="bg-tint-1"></div>
            </div>
            <div className="container">

              <div className="columns is-1 is-mobile">
                <div className="column">
                  <h1 className="title">{ betSite.name.text } review</h1>
                  <div className="lead" dangerouslySetInnerHTML={{ __html: betSite.lead.html}}></div>
                </div>
              </div>

              <div className="columns is-3 is-mobile is-multiline">
                <div className="column">
                  <div className="box">
                      <div className="level">
                        <div className="level-item">
                            {/* <h1 className="subtitle">{betSite.name.text} register bonus</h1> */}
                          <div className="betsite-logo" style={{marginTop: '1rem'}}>
                            <Img fluid={ betSite.logo.localFile.childImageSharp.fluid } alt={ betSite.name.text } />
                          </div>
                        </div>
                      </div>
                      <div className="level">
                        <div className="level-item">
                          <div className="subtitle">
                            {betSite.bonus}
                          </div>
                        </div>
                      </div>
                    <div className="level">
                      <div className="level-item">
                        <a href={'/go/' + uid} rel="nofollow" target="_blank" style={buttonStyle} className="button is-rounded is-dark">Register to {betSite.name.text}</a>
                      </div>
                    </div>
                    <div className="level">
                      <div className="level-item has-text-centered">
                          <small>18+ Terms & Conditions apply.</small>
                      </div>
                    </div>
                  </div>
                </div>
    

                <div className="column is-5-desktop is-5-tablet is-5-widescreen is-12-mobile">
									<div className="box">
										<div className="level is-mobile">
											<div className="level-item">
		                  	<h2 className="subtitle">{betSite.name.text} ratings</h2>
											</div>
                  	</div>
                  	<div className="level is-mobile" style={{margin: '0'}}>
											<div className="level-left">
                        <div className="level-item heading tag is-info">
    	                  	Esportstips rating score
                        </div>
											</div>
                      <div className="level-right">
                        <div className="level-item">
    	                  	<BetCardRating rating={betSite.rating}></BetCardRating>
                        </div>
											</div>
                  	</div>
                    <hr></hr>
                    <div className="level is-mobile" style={{margin: '0'}}>
                      <div className="level-left">
                        <div className="level-item heading">
    	                  	Offers and bonuses
  											</div>
											</div>
                      <div className="level-right">
                        <div className="level-item">
    	                  	<BetCardRating rating={betSite.bonus_rating}></BetCardRating>
  											</div>
											</div>
                  	</div>
                    <div className="level is-mobile" style={{margin: '0'}}>
                      <div className="level-left">
                        <div className="level-item heading">
    	                  	Games and matches support
                        </div>
											</div>
                      <div className="level-right">
                        <div className="level-item">
    	                  	<BetCardRating rating={betSite.games_rating}></BetCardRating>
                        </div>
											</div>
                  	</div>
                    <div className="level is-mobile" style={{margin: '0'}}>
                      <div className="level-left">
                        <div className="level-item heading">
                          Overall usability
                        </div>
											</div>
                      <div className="level-right">
                        <div className="level-item">
    	                  	<BetCardRating rating={betSite.usability_rating}></BetCardRating>
                        </div>
											</div>
                  	</div>
                  </div>
                </div>

                <div className="column">
                  {/* <GameNav games={games} className="game-listing"/> */}
                  <div className="box">
                    <h2 className="subtitle">Games you can bet on {betSite.name.text}</h2>
                    <div className="betsite-game-listing">
                    {
                    games.map((item, index) => {
                        return (
                          <div key={index} className="level-left">
                            <div className="level-item">
                              <Link to={"/games/" + item.game.document[0].uid}>
                                <div className="box betsite-game-image">
                                  <Img fluid={ item.game.document[0].data.logo.localFile.childImageSharp.fluid } alt={ item.game.document[0].uid } />
                                </div>
                              </Link>
                            </div>
                          </div>
                        )
                    })}
                    </div>

                    <div style={{marginTop: '2rem'}}>
                      <h4 className="heading">Share {betSite.name.text} review</h4>
                      <PageShare
                        url={url}
                        title={betSite.name.text + " review"}
                        description={betSite.description.text}
                        size="38">
                      </PageShare>
                    </div>
                  </div>
                </div>

              </div>



            </div>
          </section>
          <section className="section">

          </section>
          <section className="section content-section">
            <div className="container">
            <Tabs>
              <div label="Overview">
                <div dangerouslySetInnerHTML={{ __html: betSite.content.html}}></div>
                <hr></hr>
                <div className="level">
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Website</p>
                      <p className="tag">{betSite.website.url}</p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Welcome bonus</p>
                      <p className="tag">{betSite.bonus}</p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Support</p>
                      <p className="tag">{betSite.support}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div label="Payment options">
                <h3 className="subtitle">{betSite.name.text} payment options</h3>
                <div className="betsite-game-listing">
                  {
                    paymentOptions.map((item, index) => {
                      return (
                        <div key={index} className="level-left">
                          <div className="level-item">
                            {/* <Link to={"/games/" + item.game.document[0].uid}> */}
                              <div className="box betsite-game-image">
                                <Img fluid={ item.payment_option.document[0].data.logo.localFile.childImageSharp.fluid } alt={ item.payment_option.name + ' logo'} />
                              </div>
                            {/* </Link> */}
                          </div>
                        </div>
                      )
                  })}
                </div>
              </div>
              <div label="Pros and cons">
                <div className="columns">
                      <div className="column">
                        <div>
                          <h3 className="subtitle">
                            <span className="icon success-icon has-text-success">
                              <i className="material-icons">thumb_up</i>
                            </span>
                            {betSite.name.text} pros
                          </h3>
                          <div className="has-list-items success" dangerouslySetInnerHTML={{ __html: betSite.pros.html}}></div>
                        </div>
                      </div>
                      <div className="column">
                        <div>
                          <h3 className="subtitle">
                            <span className="icon danger-icon has-text-danger">
                              <i className="material-icons">thumb_down</i>
                            </span>
                            {betSite.name.text} cons
                          </h3>
                          <div className="has-list-items danger" dangerouslySetInnerHTML={{ __html: betSite.cons.html}}></div>
                        </div>
                      </div>
                  </div>
              </div>
            </Tabs>
            </div>
          </section>
          {/* <section className="section">
            <div className="container box">
                  <div className="columns">
                      <div className="column">
                        <div>
                          <h3 className="subtitle">
                            <span className="icon success-icon has-text-success">
                              <i className="material-icons">thumb_up</i>
                            </span>
                            {betSite.name.text} pros
                          </h3>
                          <div className="has-list-items success" dangerouslySetInnerHTML={{ __html: betSite.pros.html}}></div>
                        </div>
                      </div>
                      <div className="column">
                        <div>
                          <h3 className="subtitle">
                            <span className="icon danger-icon has-text-danger">
                              <i className="material-icons">thumb_down</i>
                            </span>
                            {betSite.name.text} cons
                          </h3>
                          <div className="has-list-items danger" dangerouslySetInnerHTML={{ __html: betSite.cons.html}}></div>
                        </div>
                      </div>
                  </div>
            </div>
          </section> */}
          <section className="section">
          	<div className="container">
							<h3 className="subtitle">Other esports betting sites</h3>
							<p>See other betting sites which offer great bonuses and benefits for esports betting.</p>
							<BetSiteListing sites={sites} className="bet-site-listing" />
						</div>
          </section>
          {/* <InfoBlocks infoItems={betSite.info_blocks} /> */}
          {/* <Faq faqItems={betSite.faq} /> */}
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BetsiteBySlug($slug: String!) {
    prismicBetsite(uid: {eq: $slug}) {
			uid
    	data {
      	name {
        	text
      	}
      	lead {
          html
      	}
      	metatitle {
          text
        }
        description {
          text
        }
        content {
          html
        }
        pros {
          html
        }
        cons {
          html
        }
        logo {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        image {
          url
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        brand_color
        bonus
        rating
        bonus_rating
        games_rating
        usability_rating
        support
        website {
          url
        }
        terms_and_conditions_link {
          url
        }
      	games {
        	game {
            document {
              uid
              data {
                name {
                  text
                }
                logo {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
        	}
      	}
      	payment_options {
        	payment_option {
            document {
              data {
                name
                logo {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
        	}
      	}
    	}
    }
    allPrismicBetsite(
      limit: 6
      filter: {uid: {ne: $slug}}) {
      edges {
          node {
            uid
            data {
              name {
                text
              }
              website {
                url
              }
              terms_and_conditions_link {
                url
              }
              bonus
              brand_color
              logo {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
  }

`;

