import React, {Component} from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"
import "./PageShare.sass"

import {
    FacebookShareButton,
    GooglePlusShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    RedditShareButton,
    FacebookShareCount,
    GooglePlusShareCount,
    LinkedinShareCount,
    RedditShareCount,
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    GooglePlusIcon,
    LinkedinIcon,
    RedditIcon
  } from "react-share";

class PageShare extends Component {
    
    render() {
    const url = this.props.url
    const title = this.props.title
    const description = this.props.description
    const size = this.props.size
    return (
                    <div className="level">
                      <div className="page-share-links">
                        <a>
                            <RedditShareButton url={url} title={title}>
                            <RedditIcon size={size}/>
                            </RedditShareButton>
                        </a>
                        <a>
                            <TwitterShareButton url={url} title={title}>
                            <TwitterIcon size={size}/>
                            </TwitterShareButton>
                        </a>
                        <a>
                            <FacebookShareButton url={url} quote={description}>
                            <FacebookIcon size={size}/>
                            </FacebookShareButton>
                        </a>
                        <a>
                            <LinkedinShareButton
                            url={url}
                            title={title}
                            description={description}
                            >
                            <LinkedinIcon size={size} />
                            </LinkedinShareButton>
                        </a>
                        <a>
                            <TelegramShareButton url={url}>
                            <TelegramIcon size={size} />
                            </TelegramShareButton>
                        </a>
                      </div>
                    </div>
    );
  }
}

export default PageShare
